
// react
import React, { useState, useEffect } from 'react';

// routing
import { useLocation } from 'react-router-dom';

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from '../../../../services/redux/actions/globalErrors.action';

// style
import "./PersonalInfo.scss";

// classes
import errorClass from '../../../../services/errors/classes/errorClass';
import userInfoClass from '../../classes/userDataClass/userInfoClass';

// services
import authApi from "../../../../services/Networking/authentication";
import validateYearInitial from "../../../NavBar/components/Register/services/validate/schoolYearInitial";
import validateYear from "../../../NavBar/components/Register/services/validate/schoolYear";
import convertError from '../../../../services/errors/convertError';
import networking from '../../../../services/handleNetworking/networking/networking';
import handleEnvironment from '../../../../services/handleEnvironment/handleEnvironment';
import access from '../../../../services/access/accessCheck/access';

// components
import TextInput from "../../../../components/input/Text";
import Submit from '../../../../components/input/elements/Submit';
import Loader from '../../../../components/Loader';
import SelectInput from "../../../../components/input/Select";
import option from "../../../../components/input/elements/Select/classes/option";
import ProfilePic from '../ProfilePic/ProfilePic';
import Redux from '../../../../components/Redux/Redux';

// local errors
import firstNameErrors from "../../../NavBar/components/Register/services/errors/firstName";
import validateFirstName from "../../../NavBar/components/Register/services/validate/firstName";
import lastNameErrors from "../../../NavBar/components/Register/services/errors/lastName";
import validateLastName from "../../../NavBar/components/Register/services/validate/lastName";
import emailErrors from "../../../NavBar/components/Register/services/errors/email";
import validateEmail from "../../../NavBar/components/services/validate/email";
import levelErrors from "../../../NavBar/components/Register/services/errors/schoolLevel";
import valitateLevelInital from "../../../NavBar/components/Register/services/validate/schoolLevelInitial";
import valitateLevel from "../../../NavBar/components/Register/services/validate/schoolLevel";
import yearErrors from "../../../NavBar/components/Register/services/errors/schoolYear";
import profileErrors from "../../services/errors/userInfo";

// constants
import { INTERNAL_ERROR, SUCCESS, UNAUTHORIZED, CONFLICT } from "../../../../services/errors/constants";
import { REDUX_ERRORS } from '../../../../components/Redux/services/constants';
import { UVA_ENV } from '../../../../services/handleEnvironment/constants';
import { ADMIN, EDITOR } from '../../../../services/access/constants';

const PersonalInfo = (props) => {

    // routing
    const location = useLocation();

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    // state
    const [IsEdit, toggleIsEdit] = useState(false);
    const [rerender, triggerReRender] = useState(true);

    // data
    const[userInfo, updateUserInfo] = useState(new userInfoClass());

    const updateUserInfoState = (valueKey, newValue) => {
        updateUserInfo((value) => {
            
            const newRegInfo = new userInfoClass({
                firstName: value.firstName,
                lastName: value.lastName,
                email: value.email, 
                schoolName:value.schoolName,
                // schoolId: value.schoolId,
                schoolLevel: value.schoolLevel,
                schoolYear: value.schoolYear
            });

            newRegInfo[valueKey] = newValue;

            // validate level
            const levelValid = valitateLevelInital(newRegInfo.schoolLevel);

            const levelErrorTemp = convertError({
                errorCode: levelValid,
                updateLocally: updateSchoolLevelError,
                customErrors: levelErrors
            });

            if (!levelErrorTemp) {
            } else {
                if (levelErrorTemp.errorCode !== SUCCESS) {
                    // update global error list
                    dispatch(globalErrorsActions.addError(levelErrorTemp));
                } else {
                    updateSchoolLevelError(new errorClass({
                        errorCode: SUCCESS
                    }));
                }
            }

            // validate year
            const yearValid = validateYearInitial(newRegInfo.schoolYear);

            const yearErrorTemp = convertError({
                errorCode: yearValid,
                updateLocally: updateSchoolYearError,
                customErrors: yearErrors
            });

            if (!yearErrorTemp) {
            } else {
                if (yearErrorTemp.errorCode !== SUCCESS) {
                    // update global error list
                    dispatch(globalErrorsActions.addError(yearErrorTemp));
                } else {
                    updateSchoolYearError(new errorClass({
                        errorCode: SUCCESS
                    }));
                }
            }
            return newRegInfo;
        })
    }

    // errors
    const [fetchDataError, updateFetchDataError] = useState(null);

    // input errors
    const [userImage, updateuserImage] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [firstNameError, updateFirstNameError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [lastNameError, updateLastNameError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [emailError, updateEmailError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [schoolNameError, updateSchoolNameError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [schoolYearError, updateSchoolYearError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [schoolLevelError, updateSchoolLevelError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [loadingProfileInfo, toggleLoading] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    const schoolLevelOptions = [
        new option({
            value: "null",
            title: "Kies optie"
        }),
        new option({
            value: "5",
            title: "Gymnasium"
        }),
        new option({
            value: "1",
            title: "VWO"
        }),
        new option({
            value: "4",
            title: "HAVO/VWO"
        }),
        new option({
            value: "2",
            title: "HAVO"
        }),
        new option({
            value: "3",
            title: "VMBO"
        }),
        new option({
            value: "0",
            title: "anders"
        })
    ];
    const getLevelOption=(level)=>{
        return schoolLevelOptions.find(f=>f.value==level)?.title;
    }

    useEffect(()=>{
        toggleLoading(false);
        fetchInfo();
        updateuserImage("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAjCAYAAADxG9hnAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHoSURBVHgBzViLccIwDFW4DpARskGzQbNB2aDZoGxANgidIHSCjgDdgA2cDegGqoRtSHv+yCYcvDuRI5GeX2xZUVJABhCxokNNxsfSnP4hG8kORVGMcCvw4GRrMoVxsM/GCJ5VwBbzMVwtiAjeyY54PZhjBTmgwB7nR58qYhASK+P7hbLcYQxSEWsBGU9144hthYL6mIgWZagCHDXK8mrpI6iEdxOdWvLpBDwstnQFS7doLRBSC7k612xIUQqElEKu86wsTGwDckSFgC79Uq52KuQN5FgKfKLLN8Hr6TdhGi1UbHlQXlMsykWiekZF1rvEmJsaQL40FvVTRhCjJWto0A86HiwZ2XsmX5Ur5BRMlvbsCHAt4EHwMEJ4aUZIw57sG3RunNtC1BWXE7ghe4G02jQyQSPYXlwBOxRUVQu8dHaSB2AjqSM7vKLVM4J2kTFK6+xzPMBMCIyxmzqtPE4NzAT0p0DL1+2u2YJ+L7klfPm1//MP3c2MwhneTdDfdA0uZ07a49xiAiL8vHRhiW6onHxBnRfKw9nGgjfox4CC2TECQq1n9z+m8BBtIdws8bbm6rqHS5JXoJ/AzxCuqp9UjVuQIjIzudhADlDXl/u++07E3P9rgEMQL5cSDM4+XYqAAjKAN/hi9At8VvDeLKfEigAAAABJRU5ErkJggg==");
    }, [rerender, location]);

    const fetchInfo = async () => {
        if (user?.user) {
            var formattedUserData = new userInfoClass({
                email: user?.user?.email, 
                firstName: user?.user?.firstName,
                lastName:user?.user?.fullName, 
                schoolName: "", 
                schoolLevel: "", 
                schoolYear: ""
            });
            updateUserInfo(formattedUserData);
        }
        props.toggleLoading(false);
        return;

        const returned = await networking({
            toggleLoading: props.toggleLoading,

            api: authApi.getUser,
            apiParams: user?.user?.userId,

            updateError: updateFetchDataError,
            customErrors: profileErrors,
        });

        if (returned.status === SUCCESS) {
            const userData = returned.payload;

            // add success
            var formattedUserData = new userInfoClass({
                email: userData?.email, 
                firstName:userData?.firstName,
                lastName:userData?.lastName, 
                schoolName: userData?.school, 
                schoolLevel:userData?.schoolLevel.toString(), 
                schoolYear: userData?.schoolYear
            });
            updateUserInfo(formattedUserData);
        }
    }

    const SubmitData = async(e) => {
        const stringSchoolLevel = String(userInfo.schoolLevel);
        const stringSchoolYear = String(userInfo.schoolYear);
            
        userInfo.schoolLevel = stringSchoolLevel;
        userInfo.schoolYear = stringSchoolYear;
        // start loading
        toggleLoading(true);

        e.preventDefault();
        let validInput = true;
        // validate first name
        const firstNameValid = validateFirstName(userInfo.firstName);

        const firstNameErrorTemp = convertError({
            errorCode: firstNameValid,
            updateLocally: updateFirstNameError,
            customErrors: firstNameErrors
        });

        if (!firstNameErrorTemp) {
            validInput = false;
        } else {
            if (firstNameErrorTemp.errorCode !== SUCCESS) {
                // update global error list
                dispatch(globalErrorsActions.addError(firstNameErrorTemp));
                validInput = false;
            }
        }

        // validate last name
        const lastNameValid = validateLastName(userInfo.lastName);

        const lastNameErrorTemp = convertError({
            errorCode: lastNameValid,
            updateLocally: updateLastNameError,
            customErrors: lastNameErrors
        });

        if (!lastNameErrorTemp) {
            validInput = false;
        } else {
            if (lastNameErrorTemp.errorCode !== SUCCESS) {
                // update global error list
                dispatch(globalErrorsActions.addError(lastNameErrorTemp));
                validInput = false;
            }
        }

        // validate mail
        const mailValid = validateEmail(userInfo.email);

        const emailErrorTemp = convertError({
            errorCode: mailValid,
            updateLocally: updateEmailError,
            customErrors: emailErrors
        });

        if (!emailErrorTemp) {
            validInput = false;
        } else {
            if (emailErrorTemp.errorCode !== SUCCESS) {
                // update global error list
                dispatch(globalErrorsActions.addError(emailErrorTemp));
                validInput = false;
            }
        }

        // validate level
        const levelValid = valitateLevel(userInfo.schoolLevel);

        const levelErrorTemp = convertError({
            errorCode: levelValid,
            updateLocally: updateSchoolLevelError,
            customErrors: levelErrors
        });

        if (!levelErrorTemp) {
            validInput = false;
        } else {
            if (levelErrorTemp.errorCode !== SUCCESS) {
                // update global error list
                dispatch(globalErrorsActions.addError(levelErrorTemp));
                validInput = false;
            }
        }

        // validate year
        const yearValid = validateYear(userInfo.schoolYear);

        const yearErrorTemp = convertError({
            errorCode: yearValid,
            updateLocally: updateSchoolYearError,
            customErrors: yearErrors
        });

        if (!yearErrorTemp) {
            validInput = false;
        } else {
            if (yearErrorTemp.errorCode !== SUCCESS) {
                // update global error list
                dispatch(globalErrorsActions.addError(yearErrorTemp));
                validInput = false;
            }
        }

        if (validInput) {

            // sending information
            const authResponse = await authApi.updateUser( user.user.userId, userInfo);

            // clear errors
            dispatch(globalErrorsActions.emptyErrorList());

            // nothing returned - something weard happend
            if (!authResponse) {

                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: INTERNAL_ERROR,
                        updateLocally: null,
                        customErrors: null
                    })
                ));
            }

            // success returned, update with SUCCESS & update login
            if (authResponse.status === SUCCESS) {

                // display success
                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: authResponse.status,
                        updateLocally: null,
                        customErrors: profileErrors
                    }))
                );


                toggleLoading(false);
                toggleIsEdit(false);

            } else {
                // show auth errors locally
                if (authResponse.status === CONFLICT) {
                    convertError({
                        errorCode: authResponse.status,
                        updateLocally: updateEmailError,
                        customErrors: profileErrors
                    });
                } else if (authResponse.status === UNAUTHORIZED) {
                    convertError({
                        errorCode: authResponse.status,
                        updateLocally: null,
                        customErrors: null
                    });
                }

                // show auth errors publicly
                dispatch(globalErrorsActions.addError(convertError({
                    errorCode: authResponse.status,
                    updateLocally: null,
                    customErrors: profileErrors
                })));
            }
        }

        // stop loading
        toggleLoading(false);
    }
    
    const resetEmailAdresses = async() => {
        const returned = await authApi.resetEmails();
    }

    return (
        <div
            className="personalInfoView"
        >
            <div className='profile-pic'>
                <ProfilePic 
                    firstName={userInfo.firstName}
                    lastName={userInfo.lastName}
                    email={userInfo.email}
                    userImage = {userImage}
                />
            </div>

            <div  className='profile-form'>
                <div className='form-container'>
                    
                    {
                        handleEnvironment() === UVA_ENV && !user ? false : !user.user ? false : access({
                            accessLevel: [ADMIN, EDITOR],
                            userRoles: user.user.roles
                        }) ? 
                        <div
                            className="resetUserEmails"
                            onClick={() => resetEmailAdresses()}
                        >
                            Reset user emails
                        </div> : null
                    }

                    {
                        IsEdit ?
                        <TextInput
                            title="VOORNAAM"
                            disabled={!IsEdit}
                            valueKey="firstName"
                            dataClass={userInfo}
                            errorClass={firstNameError}
                            updateError={updateFirstNameError}
                        />
                        : 
                        <div class="textInput">
                            <div class="textInputTitle">VOORNAAM</div>
                            <div class="textInputTitle textLight">{userInfo.firstName}</div>
                        </div>
                    }

                    { 
                        IsEdit ? 
                        <TextInput
                            title="ACHTERNAAM"
                            disabled={!IsEdit}
                            valueKey="lastName"
                            dataClass={userInfo}
                            errorClass={lastNameError}
                            updateError={updateLastNameError}
                        />
                        :
                        <div class="textInput">
                            <div class="textInputTitle">ACHTERNAAM</div>
                            <div class="textInputTitle textLight">{userInfo.lastName}</div>
                        </div>
                    }
                
                    {
                        IsEdit ? 
                        <TextInput
                            title="E-MAIL"
                            disabled={!IsEdit}
                            valueKey="email"
                            dataClass={userInfo}
                            errorClass={emailError}
                            updateError={updateEmailError}
                        />
                        :
                        <div class="textInput">
                            <div class="textInputTitle">E-MAIL</div>
                            <div class="textInputTitle textLight">{userInfo.email}</div>
                        </div>
                    }
                    
                    {
                        true ? null :
                        IsEdit ?
                        <TextInput
                            title="SCHOOL"
                            disabled={!IsEdit}
                            valueKey="schoolName"
                            dataClass={userInfo}
                            errorClass={schoolNameError}
                            updateError={updateSchoolNameError}
                        />
                        : 
                        <div class="textInput">
                            <div class="textInputTitle">SCHOOL</div>
                            <div class="textInputTitle textLight">{userInfo.schoolName}</div>
                        </div>
                    }

                    {
                        true ? null :
                        IsEdit ?   
                        <SelectInput
                            title="SCHOOLJAAR"
            
                            options={[
                                new option({
                                    value: "null",
                                    title: "Kies optie"
                                }),
                                new option({
                                    value: "6",
                                    title: "6"
                                }),
                                new option({
                                    value: "5",
                                    title: "5"
                                }),
                                new option({
                                    value: "4",
                                    title: "4"
                                }),
                                new option({
                                    value: "3",
                                    title: "3"
                                }),
                                new option({
                                    value: "2",
                                    title: "2"
                                }),
                                new option({
                                    value: "1",
                                    title: "1"
                                })
                            ]}
                            
                            valueKey="schoolYear"
                            dataClass={userInfo}
                            updateDataClass={updateUserInfoState}
            
                            errorClass={schoolYearError}
                            updateError={updateSchoolYearError}
                        />
                        :
                        <div class="textInput">
                            <div class="textInputTitle">SCHOOLJAAR</div>
                            <div class="textInputTitle textLight">{userInfo.schoolYear}</div>
                        </div>
                    }
            
                    {
                        true ? null :
                        IsEdit ? 
                        <SelectInput
                            title="NIVEAU"
                            disabled={!IsEdit}
                            options={schoolLevelOptions}
                            
                            valueKey="schoolLevel"
                            dataClass={userInfo}
                            updateDataClass={updateUserInfoState}
                            errorClass={schoolLevelError}
                            updateError={updateSchoolLevelError}
                        />
                        :
                        <div class="textInput">
                            <div class="textInputTitle">NIVEAU</div>
                            <div class="textInputTitle textLight">{getLevelOption(userInfo.schoolLevel)}</div>
                        </div>
                    }
                    {
                        handleEnvironment() === UVA_ENV ? null :
                        <div className="submit" >
                            <div className="submitButtonView">{
                                loadingProfileInfo ? <Loader /> :
                                IsEdit ?
                                <Submit
                                    value="Opslaan"
                                    onClick={SubmitData}
                                />
                                :
                                <Submit
                                    value="Aanpassen"
                                    onClick={()=>{ toggleIsEdit(true); }}
                                />
                            }</div>
                            {
                                IsEdit && !loadingProfileInfo ? 
                                <Submit
                                    value="Annuleren"
                                    onClick={()=>{ toggleIsEdit(false); }}
                                /> : null
                            }
                        </div>
                    }
                </div>
            </div>

            <Redux
                showSuccess={false}
                varId={REDUX_ERRORS}
                
                reduxVar={fetchDataError}
            />
        </div>
    )
}

export default PersonalInfo;
